

.container-patch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* height: 10rem;
    background-color: var(--background-color); */
    position: sticky;
    top: 0;
    height: 45px;
}
.wrapper {
    display: flex;
    width: 100%;
    /* justify-content: space-evenly; */
    justify-content: space-between;
    align-items: center;
    background-color: white;
    background-color: rgb(27 71 19 / 94%);
    /* background: rgb(25, 118, 210); */
    color: #ffffff;
    padding: 0px 30px;
  
}

.patch-mainp-tag{
    font-size: 13px;
    line-height: 17px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    margin: auto 0;
}
.patch-mainp-tag span{
    font-size: 22px;
    font-weight: 600;
}
